import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title }) => {
    return (
        <Helmet>
            <title>{ title} - Universidad Virtual de Estudios Superiores</title>
            <meta name="description" content="Estudia donde, cuando y como quieras"/>
            <meta name="keywords" content="universidad virtual, educacion online, licenciaturas en linea, educacion a distancia"/>
            <meta name="author" content="Universidad Virtual"/>
            <meta name="theme-color" content="#5279BB"/>
        </Helmet>
    )
}

export default Meta
