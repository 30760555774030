import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { removeContact } from '../../functions/firebasedb';

export const Contact = ({ contact, aff=false, isAdmin=false }) => {

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    if(isMounted.current ){
        return (
            <tr>
                <td>
                    <div>
                        <span 
                            className={`small material-icons ${contact[1].attendedBy ? 'done': 'access_alarm'}`}
                            >
                                {contact[1].attendedBy ? 'done': 'access_alarm'}
                            </span>
                        <span>
                            <Link to={`details/${contact[0]}/`} className="contact_link">
                                { contact[1].username }
                            </Link>
                        </span>
                    </div>
                    
                </td>
                <td>
                    <a href={ `mailto:${contact[1].email}`}>{ contact[1].email }</a>
                    <a href={`tel:${contact[1].phone}`}>{ contact[1].phone }</a>
                </td>
                <td>{ contact[1].programa }</td>
                <td>
                    {
                        aff
                        ? (
                            <strong>
                                { contact[1].affcode ? contact[1].affcode : "N/A"}
                            </strong>
                        )
                        : (
                            <strong>
                                { contact[1].attendedBy ? (contact[1].attendedBy).split('@')[0] : ""}
                            </strong>
    
                        )
                    }
                </td>
                {
                    isAdmin && (
                        <td>
                            <i 
                                className="material-icons delete_icon"
                                onClick={() => removeContact(contact[0])}
                                >
                                delete
                            </i>
                        </td>
                    )
                }
            </tr>
        )
    }
}
