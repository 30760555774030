import React from 'react'

export const Photo = ({ photo }) => {

    const photoBy = photo.photoBy.split('@')[0];
    return (
        <div className="contact_photo">
            <a href={photo.photo} target="_blank" rel="noopener nofollow noreferrer">
                <img src={photo.photo}/>
                <small>{photoBy}</small>
            </a>
        </div>
    )
}
