import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

export default function LayoutInside({ children }) {
    return (
        <div>
            <Navbar/>
            <div className="containerx">
                { children}
            </div>
            <Link to="/add" className="btn-floating btn-large waves-effect waves-light red"><i className="material-icons">add</i></Link>
        </div>
    )
}