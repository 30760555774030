import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { programas } from '../constants/programas';
import { addLead } from '../functions/firebasedb';
import Meta from '../components/Fragments/Meta';

const Add = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [programa, setPrograma] = useState("")
    const history = useHistory();

    const handleSubmit = e => addLead(e, name, email, phone, programa, history);

    return (
        <div>
            <Meta title="Nuevo Lead"/>
            <h5>Nuevo lead</h5>
            <div className="card">
                <div className="card-content contact_content">
                    <form onSubmit = { handleSubmit }>
                        <div className="row">
                            <div className="col s12 m6">
                                <label htmlFor="name">Nombre completo</label>
                                <input 
                                    type="text" 
                                    className="validate form-control" 
                                    onChange={ e => setName(e.target.value)}
                                    id="name" 
                                    placeholder="Escribe aquí..." />
                            </div>
                            <div className="col s12 m6">
                                <label htmlFor="email">Correo electrónico</label>
                                <input 
                                    type="email" 
                                    className="validate form-control" 
                                    onChange={ e => setEmail(e.target.value)}
                                    id="email" 
                                    placeholder="Escribe aquí..." />
                            </div>
                            <div className="col s12 m6">
                                <label htmlFor="phone">Teléfono</label>
                                <input 
                                    type="tel" 
                                    className="validate form-control" 
                                    onChange={ e => setPhone(e.target.value)}
                                    id="phone" 
                                    placeholder="Escribe aquí..." />
                            </div>
                            <div className="col s12 m6">
                                <label htmlFor="programa">Programa de interés</label>
                                <select 
                                    className="validate form-control" 
                                    id="programa" 
                                    placeholder="Escribe aquí..."
                                    onChange={e => setPrograma(e.target.value)}
                                    >
                                    {programas.map(programa => (
                                        <option key={programa} value={programa}>{programa}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col s12 text-right">
                                <br />
                                <button className="btn blue darken-3" type="submit">Guardar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add;