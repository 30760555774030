import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';

import { Photo } from '../components/Fragments/Photo';
import { UploadSnapshot } from '../components/Fragments/UploadSnapshot';
import { Attended, NotAttended } from '../components/Fragments/Attended';
import { fetchContact, fetchPhotos } from '../functions/firebasedb';
import { AuthContext } from '../auth/AuthContext';
import Meta from '../components/Fragments/Meta';

const Details = () => {

    const  { user: { email } } = useContext(AuthContext);
    const [ contact, setContact ] = useState(null);
    const [ photos, setPhotos ] = useState(null)
    const [ resultado, setResultado ] = useState("")

    const { id } = useParams();
    const history = useHistory();
    
    useEffect(() => {
        fetchContact(setContact, id);
        fetchPhotos( setPhotos, id);
        return () => {}
    },[])

    return (
        <>
            <Link to="/">
                <h3 className="back">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
                    Atrás
                </h3>
            </Link>
            <Meta title={`Detalles: ${contact ? contact.username : ""}`}/>
            <div className="contact_content">
                <div className="row">
                    <div className="col s12 m8">
                        <div className="name">
                            <div className="avatar">
                                { contact && (contact.username).substring(0,1) }
                            </div>
                            <h4>{ contact && contact.username }</h4>
                        </div>                    
                    </div>
                    <div className="col s12 m4">
                        {
                            contact && contact.attendedBy 
                                ? <Attended contact={contact} id={id} history={history} user={email}/> 
                                : <NotAttended contact={contact} id={id} history={history} email={email} resultado={resultado}/>
                        }
                    </div>
                    <div className="col s12">
                        <span>DETALLES DEL CONTACTO</span>
                        <hr/>
                        <span>STATUS: <strong>{ contact && contact.attendedBy ? "Atendido" : "Sin atender" }</strong></span>
                    </div>
                    <div className="col s12">
                        <label htmlFor="attendedBy">Atendido por</label>
                        <input 
                            type="text" 
                            id="attendedBy" 
                            defaultValue={ contact && contact.attendedBy ? contact.attendedBy : "" } 
                            readOnly/>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="registro">Registro</label>
                        <input 
                            type="text" 
                            id="registro" 
                            readOnly 
                            defaultValue={ contact && contact.registerAt }/>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="registroLlamada">Registro de la llamada</label>
                        <input 
                            type="text" 
                            id="registroLlamada" 
                            readOnly 
                            defaultValue={ contact && contact.attendedAt ? contact.attendedAt : "" }/>
                    </div>
                    <div className="col s12">
                        <hr/>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="username">Nombre completo</label>
                        <input 
                            type="text" 
                            readOnly 
                            id="username" 
                            defaultValue={contact ? contact.username : ""}/>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="email">Correo electrónico</label>
                        <a href={ `mailto:${ contact ? contact.email : ''}`}>
                            <input 
                                type="text" 
                                readOnly 
                                id="email" 
                                defaultValue={ contact ? contact.email : "" } />

                        </a>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="phone">Celular</label>
                        <a href={`tel:${ contact ? contact.phone : ''}`}>
                            <input 
                                type="text" 
                                readOnly 
                                id="phone" 
                                defaultValue={ contact ? contact.phone : "" } />
                        </a>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="programa">Programa de interés</label>
                        <input 
                            type="text" 
                            readOnly 
                            id="programa" 
                            defaultValue={ contact ? contact.programa : "" } />
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="resultado">Resultado de la llamada</label>
                        <textarea 
                            type="text" 
                            id="resultado" 
                            rows="20" 
                            className="materialize-textarea" 
                            placeholder="Escribe el resultado de la llamada" 
                            readOnly={contact && contact.attendedBy}
                            defaultValue={ contact && contact.result ? contact.result : resultado }
                            onChange={e => setResultado(e.target.value)}
                            >
                                
                            </textarea>
                    </div>
                    <div className="col s12 m6">
                        <label htmlFor="affcode">Cófigo de afiliado</label>
                        <input 
                            type="text" 
                            readOnly 
                            id="affcode" 
                            defaultValue={ 
                                contact 
                                ? (
                                    contact.affcode
                                    ? contact.affcode
                                    : ""
                                )
                                : "" } />
                    </div>
                    <div className="col s12 m6">
                        <UploadSnapshot id={id} user={email}/>
                    </div>
                    <div className="col s12 photos_container">
                        { photos && Object.entries(photos).map(photo => <Photo key={ photo[0] } photo={photo[1]}/>) }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details;