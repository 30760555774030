export const programas = [
    "Lic. en Pedagogía",
    "Lic. en Administración",    
    "Lic. en Docencia del idioma inglés",    
    "Lic. en Marketing Digital",    
    "Ing. en Software",    
    "Mtría. en Educación Tecnológica",    
    "Mtría. en Finanzas",    
    "Mtría. en Administración e Innovación de Negocios",
    "Doc. en Educación",
    "Posdoc. en Educación",
    "Inglés para maestros", 
    "Inglés para escuelas",
    "Inglés para la familia",
    "Inglés para empresas",
    "Robótica", 
    "Arduino",
    "EVT Lego Mindstorms",
    "AEROSTEM",
    "AUTOSTEM",
    "Biomecánica",
    "Transfórmate en un profesor virtual en 4 pasos", 
    "Transfórmate en un profesor Bilingüe en 6 pasos",
    "Transfórmate en un desarrollador de software en 6 pasos",
    "Conviértete en un Especialista en Marketing Digital en 6 pasos",
    "Conviértete en un Especialista en Gestión Empresarial en 6 pasos",
    "Conviértete en un profesional en STEM",
    "Liderazgo de equipos facultados", 
    "Sentimientos – Servicio de calidad hoy y siempre",
    "Facultamiento, un estilo de vida",
    "Atendiendo al cliente molesto",
    "Rapidez",
    "Recuérdame",
    "Coaching para el éxito",
    "Leal de por vida",
    "El servicio es primero",
    "Moviéndote - Mooving Up",
    "Servicio Excepcional"
]