import * as Yup from 'yup';

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CompanyValidation = Yup.object({
    programa: Yup.array()
                .required('El programa es requerido'),
    name:     Yup.string()
                .required('El nombre es requerido'),
    lastName: Yup.string()
                .required('El apellido es requerido'),
    email: Yup.string()
            .email('Email inválido')
            .required('El email es requerido'),
    phone: Yup.string()
            .required('El Teléfono es requerido')
            .matches( phoneRegex, 'Ingresa un teléfono válido'),
    companyName: Yup.string()
                    .required('El nombre de la empresa es requerido'),
    companyJob: Yup.string()
                .required('El puesto es requerido'),
    city:   Yup.string()
                .required('La ciudad es requerida'),
    state:  Yup.string()
                .required('El estado es requerido')
});

export const LeadValidation = Yup.object({
        name: Yup.string()
                .required('El Nombre completo es requerido'),
        email: Yup.string()
                .email('Ingresa un correo válido')
                .required('El Correo electrónico es requerido'),
        phone: Yup.string()
                .matches(phoneRegex, 'Ingresa un teléfono válido')
                .required('El Teléfono es requerido'),
        programa: Yup.string()
                .required('El programa de interés es requerido')
});