import React from 'react';

import { Formik, Form } from 'formik';

import CardTextField from '../components/CardTextField';
import CardSelectField from '../components/CardSelectField';
import { programas } from '../constants/programas';
import { LeadValidation } from '../functions/validations';
import { registerLead } from '../functions/firebasedb';
import Meta from '../components/Fragments/Meta';

const RegisterLead = () => {
    return (
        <>
            <Meta title="Regístrate"/>
            <div className="empresas-registro">
                <div className="empresas-container">
                    <div className="card card-image-bg">

                    </div>
                    <div className="card card-border-top">
                        <div className="card-content">
                            <h5>Registro</h5>
                            <p>Regístrate en este formulario y continúa con tu formación profesional en UNIVES, la Universidad Virtual con más de 26 años de experiencia en el ámbito Online.</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            phone: "",
                            programa: ""
                        }}

                        validationSchema={LeadValidation}
                        onSubmit={async (values, actions) => registerLead(values, actions)}
                    >
                        <Form>
                            <CardTextField label="Nombre completo" name="name" required={true} />
                            <CardTextField label="Correo electrónico" name="email" required={true} type="email" />
                            <CardTextField label="Teléfono (celular)" name="phone" required={true} type="tel" />
                            <CardSelectField label="Programa académico de interés" name="programa" required={true} options={programas} />
                            <button className="btn red darken-3" type="submit">Enviar</button>
                            <br /><br />
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default RegisterLead
