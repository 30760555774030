import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import { useState } from 'react';

const CardSelectField = ({ label, name, options, required = false, multiple=false }) => {

    const [value, setValue] = useState([])
    return (
        <div className="card">
            <div className="card-content">
                <label htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</label>

                {
                    multiple
                    ?(
                        <div role="group" aria-labelledby="checkbox-group" className="checkbox_content">
                            {options.map(option => (
                                <p key={option.replaceAll(' ')}>
                                    <label >
                                        <Field type="checkbox" className="filled-in" name={name} value={option}/>
                                        { option }
                                    </label>
                                </p>
                            ))}
                        </div>
                    )
                    : (
                        <Field 
                        as="select" 
                        name={name}
                        id={name} 
                        className="form-control"
                        >
                        { options.map(option => (
                            <option value={option} key={option.replaceAll(' ').toLowerCase()}>{option}</option>
                        ))}
                    </Field>
                    )
                }           

                <ErrorMessage name={name} component="div" className="error" />
            </div>
        </div>
    )
}

CardSelectField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool
};

export default CardSelectField
