import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import LayoutInside from '../layouts/LayoutInside';

export const PrivateRoute = ({ isAuthenticated, component:Component, ...rest }) => {

    sessionStorage.setItem('lastpath', rest.location.pathname)

    return (
        <div>
            <Route {...rest}
                component={ (props) => (
                    (isAuthenticated)
                    ? (
                        <LayoutInside>
                            <Component { ...props } />
                        </LayoutInside>
                    )
                    : (
                        <Redirect to="/login" />
                    )
                )
                }
            />
        </div>
    )
}

PrivateRoute.propTypes = {
    isAuthenticated : PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
