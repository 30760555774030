import { Link } from 'react-router-dom';
import '../assets/css/404.css';

const NotFound = () => {
    return (
        <div>
            <div className="_404_container">
                <div className="_404_content">
                    <h1 className="_404_text_main">404</h1>
                    <h5>Página no encontrada</h5>
                    <p>No te preocupes, da click en el siguiente botón y serás redireccionado a la página principal.</p>
                    <Link to="/registro" className="btn blue darken-3">Página principal</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
