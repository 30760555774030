import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import LayoutOutside from '../layouts/LayoutOutside';


export const PublicRoute = ({ isAuthenticated, component:Component, ...rest }) => {
    return (
        <div>
            <Route {...rest}
                component={ (props) => (
                    (isAuthenticated)
                    ? <Redirect to="/" />
                    : (
                        <LayoutOutside>
                            <Component { ...props } />
                        </LayoutOutside>
                    )
                )
                }
            />
        </div>
    )
}

PublicRoute.propTypes = {
    isAuthenticated : PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
