import React from 'react';
import '../assets/css/forms.css'
import { Formik, Form } from 'formik';
import TextField from '../components/TextField';
import * as Yup from 'yup';

const Testing = () => {

    const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const validate = Yup.object({
        email: Yup.string()
                .email('Email inválido')
                .required('El email es requerido'),
        phone: Yup.string()
                .matches(phoneRegex, 'Teléfono inválido')
                .required('Teléfono es obligatorio'),
        password: Yup.string()
                .required('La contraseña es requerido')
                .min(8, 'Debe ser de al menos 8 caracteres')
    });

    return (
        <div>
            <div className="container">
                <div className="card">
                    <div className="card-content">
                    <h5 className="card-title">Formik</h5>
                        <Formik
                            initialValues={{
                                email: "",
                                phone: "",
                                password: ""
                            }}
                            validationSchema={validate}
                            onSubmit={ async (values) => {
                                console.log(JSON.stringify(values))
                            }}
                            >
                                <Form>
                                    <TextField name="email" type="email" label="Correo electrónico"/>
                                    <TextField name="phone" type="tel" label="Teléfono"/>
                                    <TextField name="password" type="password" label="Contraseña"/>

                                    <br /><br />
                                    <button className="btn blue darken-3">Guardar</button>
                                </Form>

                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testing;