import React, { useContext, useEffect } from 'react';
import M from 'materialize-css';
import { Link, useHistory } from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { LogOut } from '../functions/authentication';

const Navbar = () => {
    const { dispatch } = useContext(AuthContext);

    const history = useHistory()
    const handleLogout =  () => LogOut(dispatch, history);

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, {});


        return () => {}
    },[])

    return (
        <div>
            <nav className="navbar">
                <div className="nav-wrapper">
                    <Link to="/" className="brand-logo">Realtime</Link>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                    <ul className="right hide-on-med-and-down">
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link to="/history">Historial</Link></li>
                        <li><a onClick={handleLogout}>Cerrar sesión</a></li>
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/history">Historial</Link></li>
                <li><a onClick={handleLogout}>Cerrar sesión</a></li>
            </ul>
        </div>
    )
}
export default Navbar;
