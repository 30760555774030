import React, { useState, useEffect, useContext } from 'react'
import { Contact } from '../components/Fragments/Contact';
import { fetchAllData } from '../functions/firebasedb';
import { AuthContext } from '../auth/AuthContext';
import Meta from '../components/Fragments/Meta';

const History = () => {

    const [ contacts, setContacts ] = useState({});
    const [ isAdmin, setIsAdmin ] = useState(false);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if(user.email == "programacion@unives.mx" || user.email == "comercial@unives.mx"){
            setIsAdmin(true);
        }
    }, []);
    useEffect(() => {
        fetchAllData( setContacts );
        return () => {}
    }, [])

    return (
        <div>
            <Meta title="Historial"/>
            <table className="table">
                <thead>
                    <tr>
                        
                        <th>Nombre</th>
                        <th>Contacto</th>
                        <th>Programa</th>
                        <th>Propietario</th>
                        { isAdmin && <th>Eliminar</th> }
                        

                    </tr>
                </thead>

                <tbody>
                    {
                        Object.entries(contacts).map(contact =>  {
                            if(!contact[1].deleted && contact[1].affcode == "N/A"){
                                return <Contact isAdmin={ isAdmin } key={ contact[0] } contact={ contact }/>
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default History;