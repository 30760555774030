import { ErrorMessage, Field } from 'formik';

const CardTextField = ({ label, name, type="text", required=false}) => {
    return (
        <div className="card">
            <div className="card-content">
                <div>
                    <label htmlFor={name}>{ label} { required && <span className="text-danger">*</span>}</label>
                    <Field 
                        type={type} 
                        className="browser-default" 
                        id={name}
                        name={name}
                        placeholder="Escribe aquí..."
                        />
                    <ErrorMessage name={name} component="div" className="error"/>
                </div>
            </div>
        </div>
    )
}

export default CardTextField;
