import firebase from '../constants/firebase';
import M from 'materialize-css';
import Swal from 'sweetalert2';
const dbRef = firebase.database();

export const fetchData = ( setContacts ) => {
    dbRef.ref('/people/').orderByChild('status').equalTo(1).on('value', snap => {
        if(snap.val()){
            setContacts(snap.val())
        }else{
            M.toast({html: 'No hay contactos sin atender'});
        }
    })
}

export const fetchAllData = ( setContacts ) => {
    dbRef.ref('/people/').on('value', snap => {
        if(snap.val()){
            setContacts(snap.val())
        }else{
            M.toast({html: 'No se encontraron contactos'});
        }
    })
}

export const fetchPhotos = ( setPhotos, id ) => {
    dbRef.ref(`/photo/${id}/`).on('value', snap => {
        if(snap.val()){
            setPhotos(snap.val());
        }
    });
}

export const fetchContact = (setContact, path) => {
    dbRef.ref(`/people/${path}/`).on('value', snap => {
        if(snap.val()){
            setContact(snap.val());
        }
    });
}

export const deleteContact = ( id, history, email ) => {
    dbRef.ref(`/people/${id}/`).update({
        "status": 2,
        "deleted": true,
        "deletedBy" : email
    }).then(() => {
        history.replace('/');
    })
}


export const updateContact = ( id, resid, owner ) => {
    dbRef.ref(`/people/${id}/`).update({
        "url" : `https://clientify.net/contacts/${resid}/`,
        "attendedBy" : owner,
        "attendedAt" : new Date()
    }).then(res => {
        M.toast({html: 'Propietario actualizado'});
    })
}

export const testNotifications = ( audiotag ) => {
    Notification.requestPermission().then(function(result) {
        // console.log(result);
    });
    const listener = dbRef.ref('/notifications');

    const numChildrens = localStorage.getItem('childrens') || null;

    listener.on('value', snap => {
        if(snap.val()){
            
            if( numChildrens != snap.numChildren() && numChildrens != null){
                spawnNotification("Nuevo prospecto", "https://unives.mx/wp-content/uploads/2020/12/0_cropped_rounded.png", "Realtime Phone", audiotag)
            }
            localStorage.setItem('childrens', snap.numChildren());
        }
    })
    
}

function spawnNotification(theBody,theIcon,theTitle, audiotag) {
    audiotag.play();

    var options = {
        body: theBody,
        icon: theIcon
    }
    var n = new Notification(theTitle,options);
    setTimeout(n.close.bind(n), 60000);
    }

export const crearAfiliado = ( name, setName, code, setCode  ) => {
    if(name && code){
        try{
            const ref = dbRef.ref('/affiliate');
            ref.push({
                name, code
            })
            setName("");
            setCode("");
            M.toast({ html: 'Afiliado agregado correctamente ' });

        }catch(err){
            console.log(err);
        }
    }else{
        M.toast({ html: 'Rellena los campos' });
    }
}

export const fetchAfiliados = ( setAfiliados ) => {
    const listener = dbRef.ref('/affiliate');
    listener.on('value', snap => {
        if(snap.val()){
            setAfiliados(snap.val())
        }
    })
}

export const deleteAfiliado = ( id ) => {
    try{
        const ref = dbRef.ref(`/affiliate/${id}/`);
        ref.remove();
        M.toast({ html: 'Afiliado eliminado correctamente'});
    }catch(err){
        console.log(err);
        M.toast({ html: 'error al eliminar'})
    }
}

export const updateAfiliado = ( id, name, code) => {
    try{

        dbRef.ref(`/affiliate/${id}/`).update({
            name, code
        });

        M.toast({ html: 'Actualizado correctamente '});


    }catch(err){
        console.log(err);
        M.toast({ html: 'No se pudo actualizar' });
    }
}

export const activeListener = ( id, setCounter ) => {
    dbRef.ref('/people').orderByChild('affcode').equalTo(id).once( 'value', snap => {
        if(snap.val()){
            setCounter(snap.numChildren());
        }else{
            // console.log('Not found')
            setCounter(0);
        }
    })
}

export const addLead = ( e, name, email, phone, programa, history ) => {
    e.preventDefault();
    if(name && email && phone && programa ){

        dbRef.ref(`/people/`).orderByChild('email').equalTo(email).once('value', snap => {
            if(snap.val()){
                M.toast({ html: 'Este Lead ya existe'});
            }else{
                try{
                    dbRef.ref('/people/').push({
                        username: name,
                        email: email,
                        phone: phone,
                        programa: programa,
                        registerAt: `${new Date()}`,
                        affcode: "N/A",
                        clientify: false,
                        free: true,
                        status: 1
                    });
                    M.toast({ html: 'Lead agregado correctamente'});
                    history.push('/');
                }catch(err){
                    console.log(err);
                    M.toast({ html: 'Error al agregar', err});
                }
            }
        })

    }else{
        M.toast({ html: 'Ingresa datos válidos'});
    }
}

export const removeContact = async ( id ) => {
    if(id){
        const peopleRef = dbRef.ref(`/people/${id}`);
        peopleRef.update({
            deleted: true
        });
        M.toast({ html: 'Eliminado correctamente'});
    }
}

// Forms 

export const registerCompany = async ( { programa, name, lastName, email, phone, companyName, companyJob, city, state }, actions ) => {
// export const registerCompany = async ( values, actions ) => {
   let programaAll = "";

   Object.entries(programa).map(program => {
       programaAll += " - " + program[1] + ' ';
   })
   
    await dbRef.ref('people').push({
        username: `${name} ${lastName}`,
        email: email,
        phone: phone,
        programa: programaAll,
        registerAt: `${new Date()}`,
        affcode: "N/A",
        clientify: false,
        free: true,
        status: 1,
        meta: `- Nombre de la empresa: ${companyName} -
               - Puesto en la empresa: ${companyJob} - 
               - Ciudad : ${city} - 
               - Estado: ${state} -
               - Asunto: CONVENIO EMPRESAS
               `
    });

    Swal.fire({
        title: '¡Registrado exitosamente!',
        text: 'En breve te contactaremos',
        icon: 'success',
        confirmButtonText: 'Ok'
    }).then((result) => {
        actions.resetForm()
    })
}

export const registerLead = async({ name, email, phone, programa }, actions ) => {

    await dbRef.ref('people').push({
        username: name,
        email, phone, programa,
        registerAt: `${new Date()}`,
        affcode: 'N/A',
        clientify: false,
        free: true,
        status: 1,
        meta: ` Lead creado a tráves del fomulario realtime`
    });
    Swal.fire({
        title: '¡Registrado exitosamente!',
        text: 'En breve te contactaremos',
        icon: 'success',
        confirmButtonText: 'Ok'
    }).then((result) => {
        actions.resetForm()
    })
}