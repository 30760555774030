import React, { useState, useContext } from 'react'
import cardbg from '../assets/img/bg2.png';

import { AuthContext } from '../auth/AuthContext';
import { SignIn } from '../functions/authentication';
import Meta from '../components/Fragments/Meta';

const Login = ({ history }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { dispatch } = useContext(AuthContext)

    const handleSubmit = e => SignIn(e, email, password, history, dispatch);


    return (
        <>
            <Meta title="Iniciar sesión" />
            <div className="container">
                <div className="row login_card_content">
                    <div className="col s12 m7 login_card">
                        <div className="card">
                            <div className="card-image">
                                <img src={cardbg} alt="card header background" />
                            </div>
                            <div className="card-content">
                                <h5>Iniciar sesión</h5>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Correo electrónico</label>
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="example@unives.mx"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Contraseña</label>
                                        <input
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group center-align">
                                        <button className="btn btn-primary blue darken-4 waves-effect waves-light">Iniciar sesión</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;