import { useEffect, useReducer } from 'react';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/AuthReducer';
import { testNotifications } from './functions/firebasedb';

import 'materialize-css/dist/css/materialize.min.css';
import './assets/css/app.css';
import notification from './assets/audio/notification.m4a';

import Navigation from './routes/Navigation'

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false };
}


function App() {

  const [ user, dispatch ] = useReducer(authReducer, {}, init);
  
  useEffect(() => {
    
    localStorage.setItem('user', JSON.stringify(user));
    return ()=>{}
  },[user]);

  
  useEffect(() => {
    const audiotag = document.getElementById('audiotag');
    // audiotag.click();
    testNotifications( audiotag )
    return () => {}
  }, [user])

  return (
    <AuthContext.Provider value={{user, dispatch}}>
      <Navigation/>
      <audio src={ notification } id="audiotag"></audio>
    </AuthContext.Provider>
  );
}

export default App;
