import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { AuthContext } from '../auth/AuthContext'

import Home from '../pages/Home';
import Login from '../pages/Login';
import History from '../pages/History';
import Details from '../pages/Details';
import Add from '../pages/Add';
import RegisterEmpresa from '../pages/RegisterEmpresa';
import NotFound from '../pages/NotFound';
import Testing from '../pages/Testing';
import RegisterLead from '../pages/RegisterLead';


const Navigation = () => {

    const { user } = useContext(AuthContext)
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoute exact path="/" component={ Home } isAuthenticated={ user.logged } />
                <PublicRoute exact path="/login" component={ Login } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/history" component={ History } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/details/:id" component={ Details } isAuthenticated={ user.logged } />
                <PrivateRoute exact path="/add" component={ Add } isAuthenticated={ user.logged } />
                <Route exact path="/empresas" component={ RegisterEmpresa }/>
                <Route exact path="/registro" component={ RegisterLead }/>
                <Route exact path="/testing" component={Testing}/>

                <Route path="*" component={ NotFound }/>
            </Switch>
        </BrowserRouter>
    )
}

export default Navigation;