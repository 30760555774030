import { updateContact } from '../functions/firebasedb';
import M from 'materialize-css';

export const sendToClientify = async( contact, resultado, owner, id ) => {
    console.log('contact', contact);
    console.log('resultado ', resultado);
    console.log('owner ', owner);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token d4029a908e7a6bbf43f3148a86b6d23c6d8f662b");
    myHeaders.append("Content-Type", "application/json");

    const affcode = contact.affcode || "N/A";

    var datos = JSON.stringify({
        "first_name": contact.username,
        "email": contact.email,
        "phone": contact.phone,
        "status": "cold-lead",
        "contact_source": "realtime",
        "addresses": [],
        "custom_fields": [
            { field: 'PROSPECTOS: Programa de interés', value: contact.programa},
            { field: 'programa-interes-facebook', value: contact.programa},
            { field: 'affcode', value: affcode}
        ],
        "wall_entries": [
            {
                "user": owner,
                "type": "call",
                "created" : new Date(),
                "extra" : resultado
            }
        ],
        "description": "Contacto creado desde Realtime",
        "message": resultado,
        "summary": resultado,
        "owner": owner
    });

    var reqOptions = {
        method: 'POST',
        headers: myHeaders,
        body: datos,
        redirect: 'follow'
    };
    if(resultado){
        await fetch('https://api.clientify.net/v1/contacts/', reqOptions)
            .then(res => res.json())
            .then(res => {
                console.log('ressss : ', res);
                console.log('url   ', res.url);
                M.toast({ html: 'Enviado a Clientify'});

                if( res.id){
                    
                    updateContact( id, res.id, owner);
                }

            }).catch(err => {
                console.log('Contact cannot created, error:',err);
                M.toast({ html: 'No se pudo crear el contacto.'})
            })
    }else{
        M.toast({ html: 'Escribe el resultado de la llamada'});
    }
    


}