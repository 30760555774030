import { Formik, Form } from 'formik';
import '../assets/css/empresas.css';

import { programas } from '../constants/programas';
import { registerCompany } from '../functions/firebasedb';
import CardTextField from '../components/CardTextField';
import CardSelectField from '../components/CardSelectField';
import { CompanyValidation } from '../functions/validations';
import Meta from '../components/Fragments/Meta';

const RegisterEmpresa = () => {

    return (
        <>
            <Meta title="Convenios con empresas"/>
            <div className="empresas-registro">
                <div className="empresas-container">
                    <div className="card card-image-bg">

                    </div>
                    <div className="card card-border-top">
                        <div className="card-content">
                            <h5>Registro para empresas</h5>
                            <p>UNIVES en colaboración con tu empresa, ofrece becas únicas para ti, regístrate para ser candidato a una de ellas.</p>
                        </div>
                    </div>
                    <Formik
                        initialValues={{
                            programa: "",
                            name: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            companyName: "",
                            companyJob: "",
                            city: "",
                            state: ""
                        }}
                        validationSchema={CompanyValidation}
                        onSubmit={async (values, actions) => {
                            registerCompany(values, actions)
                        }}
                    >
                        <Form>
                            <CardSelectField label="Oferta educativa en la que estás interesado (a)" name="programa" required={true} options={programas} multiple={true} />
                            <CardTextField label="Nombre (s)" type="text" name="name" required={true} />
                            <CardTextField label="Apellidos" type="text" name="lastName" required={true} />
                            <CardTextField label="Correo electrónico" type="email" name="email" required={true} />
                            <CardTextField label="Teléfono (celular)" type="tel" name="phone" required={true} />
                            <CardTextField label="Nombre de la empresa" type="text" name="companyName" required={true} />
                            <CardTextField label="Puesto en la empresa" type="text" name="companyJob" required={true} />
                            <CardTextField label="Ciudad" type="text" name="city" required={true} />
                            <CardTextField label="Estado" type="text" name="state" required={true} />

                            <button className="btn red darken-4" type="submit">Enviar</button>
                            <br /><br />
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default RegisterEmpresa;