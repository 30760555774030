import React from 'react'
import firebase from '../../constants/firebase';
import M from 'materialize-css';

const stgRef = firebase.storage();
const dbRef = firebase.database();

export const UploadSnapshot = ({ id, user }) => {
    
    const handleChange = (e) => {
        if(e.target.files[0]){
            M.toast({ html: 'Subiendo archivo, espera un momento.'});
            var uploadTask = stgRef.ref(`/screen/${e.target.files[0].name}`).put(e.target.files[0])

            uploadTask.on('state_changed', function(snapshot){
                
              }, function(error) {
                M.toast({html: 'Ha ocurrido un error, inténtalo más tarde'});
              }, function() {
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    dbRef.ref(`/photo/${id}/`).push({
                        photo: downloadURL,
                        photoBy: user
                    });
                    M.toast({ html: 'Archivo subido exitosamente.'});
                    e.target.value = "";
                });
              });
        }
    
    }

    return (
        <div>
            <label htmlFor="">Captura de pantalla</label>
            <br/>
            <input type="file" name="snapshop" id="snaphost" className="input_file" onChange={handleChange}/>
        </div>
    )
}
