import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const TextField = ({ label, name, type }) => {
    return (
        <>
            <label htmlFor={name}>{label}</label>
            <Field type={type} id={name} name={name} className="browser-default"/>
            <ErrorMessage name={name} component="div" className="error"/>
        </>
    )
}

TextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
};

export default TextField;
