import M from 'materialize-css';
import firebase from '../constants/firebase';

import { types } from '../types/types';

export const SignIn = (e, email, password, history, dispatch) => {
    e.preventDefault();
    if(email && password){
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(user => {
                history.push('/');
                dispatch({
                    type: types.login,
                    payload: {
                        email: user.user.email
                    }
                })
            })
            .catch(err => {
                M.toast({html: 'Usuario/contraseña incorrectos'});
            });
    }else{
        M.toast({ html: 'Rellena los campos'})
    }
}

export const LogOut = ( dispatch, history ) => {
    dispatch({
        type: types.logout,
        payload: {
            email: null
        }
    });

    firebase.auth().signOut().then(() => {
        M.toast({html: 'Has cerrado sesión correctamente'});
    }).catch(err => {
        console.log('An error happened');
    })
    history.replace('/login');
}