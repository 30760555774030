import React from 'react'
import { deleteContact } from '../../functions/firebasedb';
import { sendToClientify } from '../../functions/clientify';

export const Attended = ({ contact, id, history, user }) => {

    const handleDelete = () => {
        deleteContact(id, history, user);
        // history.replace('/');
    };

    return (
        <div className="attended">
            <button 
                className="waves-effect waves-light btn red darken-1 mr-2"
                onClick={handleDelete}
                >
                    <i className="material-icons left">delete</i>
                    Eliminar
            </button>
            <a href={contact.url} target="_blank" rel="noopener nofollow noreferrer" className="waves-effect waves-light btn blue">
                <i className="material-icons left">link</i>
                Ver en Clientify
            </a>
        </div>
    )
}

export const NotAttended = ({ contact, id, email, resultado, history }) => {
    
    const handleDelete = () => {
        deleteContact(id, history, email);
    }

    const sendClientify = () => sendToClientify(contact, resultado, email, id);

    return(
        <div className="attended">
            <button 
                className="waves-effect waves-light btn btn red darken-1 mr-2"
                onClick={handleDelete}
                >
                <i className="material-icons left">delete</i>
                Eliminar
            </button>
            <a  
                className="waves-effect waves-light btn blue"
                onClick={ sendClientify }
                >
                <i className="material-icons left">cloud_upload</i>
                Enviar a Clientify
            </a>
        </div>
    )
}
