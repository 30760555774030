import React, { useState, useEffect } from 'react'
import { Contact } from '../components/Fragments/Contact';
import { fetchData } from '../functions/firebasedb';
import Meta from '../components/Fragments/Meta';

const Home = () => {

    const [ contacts, setContacts ] = useState({});

    useEffect(() => {
        fetchData( setContacts );
        return () => {}
    }, [])
    return (
        <div>
            <Meta title="Contactos"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Contacto</th>
                        <th>Programa</th>
                        <th>Affcode</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        Object.entries(contacts).map(contact =>  {
                            if(contact[1].affcode == "N/A"){
                                return (
                                    <Contact aff={true} key={ contact[0] } contact={ contact }/>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Home;